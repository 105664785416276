@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
/*Stylesheet for the table titel poker2gether*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    --primary-color: #ab1612;
    --secondary-color: #3a3E41;

    --header-background-color: #252525;
    --header-text-color: #e8ebee;

    --button-text-color: white;

    --poker-darkgreen-color: #0c622d;
    --poker-green-color: #0a893a;

    margin: 0;
}

.PokerPageStylesheet_body__VrycX {
    background: url(/static/media/red-carpet.257be70e.jpeg) no-repeat fixed;
    background-size: cover;
}

@media (max-width: 959px) {
    .PokerPageStylesheet_body__VrycX {
        overflow-y: auto !important;
    }
}

.PokerPageStylesheet_pokerPageContainer__zPoM_ {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.PokerPageStylesheet_confettiCanvas__2NAbZ {
    position: fixed !important;
}

@media (min-width: 960px) {
    .HomePageStylesheet_homePageContainer__1ZeGJ {
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
